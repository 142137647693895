@tailwind base;
@tailwind components;
@tailwind utilities;

/* Apply Ubuntu font to the entire application */
body {
  font-family: "Ubuntu", sans-serif;
}

.markdown {
  > * {
    all: revert;
  }

  font-size: 13px;

  p {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  &.prose {
    li,
    ul,
    ol {
      margin-bottom: 0px !important;
      margin-top: 0px !important;
      line-height: normal;
    }
    font-size: 13px;

    max-width: initial;

    max-width: 100%;

    :where(pre):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
      all: initial;
    }

    code {
      font-size: 13px;
      background-color: #f5f5f5;
      padding: 0.2em 0.4em;
      border-radius: 0.3em;
      font-family: "Courier New", Courier, monospace;
      font-weight: normal;
      color: black;
      &::after {
        content: none;
      }

      &::before {
        content: none;
      }
    }

    ul {
      li {
        margin-bottom: 0px;
      }
    }
  }
}

html,
body,
#root {
  height: 100%;
}

/* Scrollbar styles */
::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  border-radius: 40px;
  background: #f1f5f9;
}

::-webkit-scrollbar-thumb {
  background: #cbd5e1;
  border-radius: 40px;

  &:active {
    background-color: #006cfa;
  }
}

/* Additional styles */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Ubuntu", sans-serif;
  font-weight: 700; /* Bold */
}

p,
span,
div,
a,
button {
  font-family: "Ubuntu", sans-serif;
  font-weight: 400; /* Regular */
}
